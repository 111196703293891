

@font-face {
  font-family: 'futura-pt';
  font-weight: bold;
  src: url("/assets/fonts/Futura-PT/FuturaPTLight.otf") format("opentype");
}

@font-face {
  font-family: 'futura-pt bold';
  font-weight: bold;
  src: url("/assets/fonts/Futura-PT/FuturaPTBold.otf") format("opentype");
}

@font-face {
  font-family: 'futura-pt medium';
  font-weight: 500;
  src: url("/assets/fonts/Futura-PT/FuturaPTMedium.otf") format("opentype");
}

html {
  font-family: 'futura-pt medium', sans-serif;
  font-weight: 500;
  font-size: 1em;
  color:#333;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  font-family: 'futura-pt medium', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  margin: 0;
  padding: 0;
}


#root {
  position: relative;
  margin: 0;
  top: 0;
  padding: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ub-layout,
.ub-layout >
.ub-content,
.ub-layout >
.ub-content > 
.ub-layout 
.ub-footer {
  padding: 0;
  margin: 0;
}

.ub-layout {
  position: relative;
  display: grid;
  grid-template-rows: auto auto auto;
  height: 100%;
  max-width: 1920px;
  margin: 0 auto;
}


/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
  .ub-footer
  .ub-footer-content_2
  .navbar-brand > img {
   max-height:23px;
  }

  .ub-article
  .ub-article-content-card {
    margin:4vw 0 1vw 0;
  }

}
/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
  .ub-footer
  .ub-footer-content_2
  .navbar-brand > img {
    max-height:40px;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .fixed {
    border-color: white !important;
  }

  .ub-navbar-wrapper
  .navbar
  .navbar-nav >
  .fixed:hover,
  .ub-navbar-wrapper
  .navbar
  .navbar-nav >
  .fixed:hover > .nav-link {
    background:white;
    color:#840C20;
  }
  
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  .ub-article
  .ub-article-content-card {
    margin:6vw 3vw 1vw 3vw;
  }

  
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  .prefer-max-width {
    max-width:1028px !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1440px) {
 .prefer-max-width {
    max-width:1366px !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1920px) {
  .prefer-max-width {
    max-width:1600px !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 2160px) {
 
}

/* <-- Start Nav Toggle  --> */

.ub-navbar-toggler {
  border-color: white;
}

.ub-navbar-toggler
.ub-navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.7)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.ub-navbar-wrapper {
  background: #A7162E;
  
  /* position: relative; */
}

.ub-navbar-wrapper
.navbar {
  background: #A7162E;
  margin:.3vw auto;
}

.ub-navbar-wrapper
.navbar >
.navbar-brand {
  padding: 0;
}

.ub-navbar-wrapper >
.navbar >
.navbar-brand > 
img {
  height: calc(1.6em + .6vw);
  max-height:40px;
  margin: auto 0;
}

.ub-navbar-wrapper
.navbar
.navbar-nav >
.nav-item {
  padding:0 calc(.8em + 0.2vw);
}

.ub-navbar-wrapper
.navbar
.navbar-nav >
.nav-item:last-child {
  margin-left: 1vw;
}

.ub-navbar-wrapper
.navbar
.navbar-nav >
.nav-item >
.nav-link {
  font-size: calc(.9em + .06vw);
  color: white;
  font-weight: 500;
  text-decoration: none;
  padding:.3em 0 !important;
  display: inline-block;
  border-bottom:.1em solid transparent;
}

.ub-navbar-wrapper
.navbar
.navbar-nav >
.nav-item >
.nav-link:not(.fixed):hover  {
  border-bottom-color:white;
}

.ub-navbar-wrapper
.navbar
.navbar-nav >
.fixed {
  border:0.12em solid transparent;
}


/* CONTENT */

.ub-content-wrapper {
  position: relative;
  box-sizing: border-box;
  height: 100%;
}

.ub-content-wrapper > 
.ub-content > 
.row  > div {
  padding: 0;
}

.ub-article
.ub-article-content-card
.ub-divider {
  margin: 2vw auto;
  display: block;
  height:1px;
  padding: 1px;
  border-bottom:1px solid #DADCE0;
}

.ub-article
.ub-article-content-card
.ub-list-col-2 {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 5px;
  width: 100%;
}

.ub-article
.ub-article-content-card
.ub-list-dash {
  list-style-type: none ;
  justify-items: left;
  padding-left: 1em !important;
  margin-bottom: 1em !important;
}

.ub-article
.ub-article-content-card
.ub-list-col-2 > div >
ul {
  list-style-type: none;
}

.ub-article-content-card
.ub-list-dash >
li::before {
  content: '- ';
  margin-left: -.8em;
}


.ub-article
.ub-article-content-card
._spacer {
   margin:calc(1.2em + 1vw) 0;
}
.ub-article
.ub-article-content-card
h4 {
  font-size:calc(0.9em + 0.3vw);
  font-weight: 700;
  margin-top: 2vw;
  margin-bottom: 2vw;
}

.ub-article
.ub-article-content-card
h5 {
  font-size:calc(0.8em + 0.3vw);
  font-weight: 700;
  margin-top: 1em;
  margin-bottom:1em;
}

.ub-article
.ub-article-content-card
p {
  font-size:calc(0.8em + 0.3vw);
}

.ub-article
.ub-article-content-card
.link {
  text-decoration: none;
  font-size:100%;
  color: #A7162E;
}

.ub-article
.ub-article-content-card
.p-sm {
  font-size:calc(0.75em + 0.3vw);
}

.ub-article
.ub-article-content-card
.ub-list-dash {
  font-size:calc(0.8em + 0.3vw);
}

.ub-article
.ub-article-content-card
ul:not(.ub-list-dash) {
  font-size:calc(0.8em + 0.3vw);
  margin:calc(.9em + .8vw) 0;
}



/* BACKGROUND HEADER */

.ub-background-header {
  background-size: cover;
  background-color: #100d09;
  background-repeat: no-repeat;
  background-position: center top;
  padding:0;
  margin:0;
  position: relative;
  box-sizing: border-box;
  height:calc(100vw / 1.8);
}

.ub-background-header
.ub-background-header-content {
  position: absolute;
  width:100%;
  height:auto;
  top:49%;
  left:50%;
  padding:1vw calc(.6em + 1vw) !important;
  transform: translate(-50%, -50%);
  margin:.6em;
}

.ub-background-header
.ub-background-header-content >
div {
  color:white;
}

.ub-background-header
.ub-background-header-content 
h1 {
   font-family: 'futura-pt bold';
   font-size: calc(1.6em + 2.8vw);
   margin: 0;
   padding: 0;
}

.ub-background-header
.ub-background-header-content 
p:not(.spacer_xs) {
  font-family: 'futura-pt';
  font-size: calc(.8em + .8vw);
  line-height: 1.2;
}

.ub-background-header
.ub-background-header-content
.learn-more {
   display: grid;
   grid-template-columns: calc(.7em + .1vw) auto;
   grid-column-gap: .8vw;
   text-decoration: none;
   color:white;
   font-size: calc(.6em + .08vw);
}

.ub-background-header
.ub-background-header-content
.learn-more > img {
  width: 98%;
}

.ub-background-header
.ub-background-header-content
.learn-more > span {
  margin-top:-1px;
}

.preload-images {
  background: url('/assets/images/bg/home.png') no-repeat -9999px -9999px;
	background: url('/assets/images/bg/home.png') no-repeat -9999px -9999px,
              url('/assets/images/bg/thank-you.png') no-repeat -9999px -9999px,
              url('/assets/images/bg/others.png') no-repeat -9999px -9999px;
}

.bkg-0001 {
  background-image: url('/assets/images/bg/home.png');
  max-height:960px;
}

.bkg-0002 {
  background-image: url('/assets/images/bg/thank-you.png');
  max-height:960px;
}


.bkg-0003 {
  background-image: url('/assets/images/bg/others.png');
  max-height:665px;
}

.ub-background-header
.ub-background-header-content.no-content {
  top:40%;
  height:auto;
}

.ub-background-header
.ub-background-header-content.no-content
.spacer_xs {
  display: block;
  line-height: 1;
  height:1.8vw;
  padding: 0;
  margin:0;
}


/* ARTICLE */

.ub-article {
  display: block;
  padding: 0;
  margin: 0;
}


.ub-content
.ub-article-row-header {
  text-align: center;
  padding:calc(2em + 6vw) 10vw;
}

.ub-content
.ub-article
.ub-article-row-header > h3 {
  font-family: 'futura-pt bold';
  font-size:calc(1.2em + 1.4vw);
  font-weight: 600;
}

/* WIDGET */

.ub-content
.ub-article
.ub-widget-wrapper {
  box-sizing: border-box;
  padding:calc(1.7em + 4vw) 0;
}

.ub-content
.ub-article
.ub-widget-wrapper >
div {
  box-sizing: border-box;
  padding:.9vw;
  
}

.ub-content
.ub-article
.ub-widget-wrapper
.ub-widget-card {
  width:100%;
  height:100%;
  padding:calc(1em + 1vw) calc(1.2em + 1vw);
}

.ub-content
.ub-article
.ub-widget-wrapper
.ub-widget-card > p
{
  font-size:calc(.8em + .4vw);
}

.ub-content
.ub-article
.ub-widget-wrapper
.ub-widget-card > h4 {
  font-weight: 600;
  font-size:calc(1em + .4vw);
  margin-bottom:calc(.9em + .8vw);
}

.ub-content
.ub-article
.ub-widget-wrapper
.ub-widget-card > h2 {
  font-size:calc(2em + .3vw);
  font-weight: 600;
  margin-bottom:calc(.8em + .8vw);
}


/* CONTACT FORM */

.ub-content
.ub-article
.ub-article-card-form {
  max-width: 700px;
  margin:0 auto;
  padding: 2vw 3vw !important;
}


.ub-content
.ub-article
.ub-article-card-form 
.ub-article-card-form-header {
  font-family: 'futura-pt bold';
  font-size: calc(1.6em + .8vw);
}


.ub-content
.ub-article
.ub-article-card-form
.form-group {
  padding:2vw 0 0 0;
}

.ub-content
.ub-article
.ub-article-card-form
.form-group
.form-control {
  border-radius: 0 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  width: 100%;
}

.error-message {
  color: red;
  font-size: calc(.8em + .2vw);
  margin-bottom:-10px;
}

.ub-content
.ub-article
.ub-article-card-form
.form-group 
.react-tel-input {
  width:100%;
}

.captcha-form-group.was-validated
iframe {
  border:1px solid #A7162E;
}

.flag-dropdown {
  border-radius: 0 !important;
  border: 0;
}
/* FOOTER */

.ub-footer {
  background: black;
}

.ub-footer,
.ub-footer > div,
.ub-footer > div > div {
  padding: 0;
  margin: 0;
}

.ub-footer
.ub-footer-content {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: .8vw;
  padding:calc(3em + 2vw) .8vw;
}

.ub-footer
.ub-footer-content
.content-left
.content-left-top
.proud-sponsor {
  font-size:calc(.9em + .4vw);
  font-weight: 600;
}

.ub-footer
.ub-footer-content
.content-left >
div {
  padding:.3vw;
}

.ub-footer
.ub-footer-content
.content-left
.content-left-bottom >
.wan-png-logo {
  height:calc(1.2em + .9vw);
}


.ub-footer
.ub-footer-content
.content-right {
  padding-top:3%;
  text-align: right;
  vertical-align: middle;
}

.ub-footer
.ub-footer-content
.content-right > p {
  font-size: calc(.8em + .4vw);
}

.ub-footer
.ub-footer-content
.content-right > p > strong {
  color: #A7162E;
  font-weight: 600;
}

.ub-footer
.ub-footer-content_2 {
  color: white;
  padding:0;
}


.ub-footer
.ub-footer-content_2 >
.row {
   padding:calc(1em + 2vw) 0;
}

.ub-footer
.ub-footer-content_2 >
.row  > div {
   padding:0;
}


.ub-footer
.ub-footer-content_2
.navbar-brand {
  margin: 0t;
  padding: 0;
}


.ub-footer
.ub-footer-content_2
.wv-location {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: .8vw;
}

.ub-footer
.ub-footer-content_2
.wv-location h4 {
  font-size: calc(.7em + .3vw);
}

.ub-footer
.ub-footer-content_2
.wv-location ._dash{
  padding-left:calc(1em + .9vw);
  position:relative;
}

.ub-footer
.ub-footer-content_2
.wv-location ._dash:before {
  content: '';
  position:absolute;
  height:calc(.1em + .02vw);
  width:calc(.8em + .3vw);
  left:0;
  background: #A7162E;
  top:50%;
  transform: translateY(-50%);
}

.ub-footer
.ub-footer-content_2
.wv-location p {
 padding-left:calc(1em + .9vw);
}

.ub-footer
.ub-footer-content_2
.wv-location p,
.ub-footer
.ub-footer-content_2
.wv-location a {
  font-family: 'futura-pt';
  font-size: calc(.6em + .3vw);
  font-weight: 300;
  color: #A0A0A0;
}

.ub-footer
.ub-footer-content_2
.wv-location a {
  font-size: 106%;
}

.ub-footer
.ub-footer-content_2
.navbar-brand > img {
  margin-top: -9px;
  height: calc(1em + .9vw);
}

.ub-footer
.ub-footer-content_2
.footer-navbar-nav >
.nav-item {
  padding: .4vw 0;;
}

.ub-footer
.ub-footer-content_2
.footer-navbar-nav >
.nav-item > .nav-link {
  display: inline;
  color: #A0A0A0;
  font-size: calc(.8em + .1vw);
  white-space: nowrap;
}

.ub-footer
.ub-footer-content_2
.ub-footer-content_2_last_row {
  font-family: 'futura-pt';
  font-size: calc(.7em + .1vw);
  font-weight: 300;
  color:#A0A0A0;
}

.ub-footer
.ub-footer-content_2
.ub-footer-content_2_last_row
.copyright {
  font-size: 100%;
  margin-left:.6vw;
}

.ub-footer
.ub-footer-content_2
.ub-footer-content_2_last_row
.last-nav {
  float: right;
  margin-right:.6vw;;
}

.ub-footer
.ub-footer-content_2
.ub-footer-content_2_last_row
.last-nav:after {
  content: '';
  clear: both;
}

.ub-footer
.ub-footer-content_2
.ub-footer-content_2_last_row
.last-nav >
.nav-item {
  padding:1px .8vw;
}

.ub-footer
.ub-footer-content_2
.ub-footer-content_2_last_row
.last-nav >
.nav-item > a {
  font-size: 100%;
  font-weight: 300;
  color: #A0A0A0;
}

/* CONTACT US */

.ub-contact-us {
   padding:calc(1em + 4vw) 0;
}

/* common styles */

.alt-row {
  background: #FDD835;
}

.row {
  margin:0 !important;
}

.row-alt-f {
  background: #F2F2F2;
  min-height: 20px;
}

.row-alt-f >
div {
  padding:8vw;
}

.bordered {
  border:1px solid #CCCCCC;
}

.prefer-max-width {
  width: 100%;
  margin-left: auto !important;
  margin-right: auto !important;
}

._spacer {
  height:calc(.6em + .4vw);
}

._spacer_xs {
  height:calc(.3em + .3vw);
}

._underline {
  padding-bottom:calc(.4em + .8vw);
  position: relative;
}

._underline:before {  
  content: '';
  position: absolute;
  bottom: 0;
  height:5px;
  width: calc(1em + .8vw);
  border-bottom:5px solid #A7162E;
}

/* CONTROLS */

.ub-btn {
  background-color: #840C20;
  border-radius: 0 !important;
  font-size: calc(.6em + 0.4vw);
  font-weight: 300;
  color: white;
  padding:calc(.04em + 0.5vw) calc(1.1em + 0.6vw);
  text-transform: uppercase;
}

.ub-btn:hover {
  background-color: white;
  border-color: #840C20;
  color: #840C20;
}

.se-pre-con {
  position: absolute !important;
  width: 100%;
  height: 100vh !important;
  left: 0;
  top: 0;
  background-image: url('/assets/images/loading/bar-loading.gif') !important;
  background-repeat: no-repeat !important;
  background-position: center 30vh !important;
  background-color: white;
  z-index: 999999 !important;
}

.ring-loader {
  position: absolute !important;
  width: 28px;
  height: 28px;
  left: calc(50vw - 14px);
  top: 40vh;
  background: url('/assets/images/loading/ring-loading.gif') no-repeat center center !important;
  z-index: 99999 !important;
}



/* FIXES */

.stick-top {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1020;
}